define("ember-form-master-2000/templates/components/fm-displays/checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{this.styles.checkboxWrapperClass}} {{if this.isFocused this.styles.focusClass}} {{if this.visibleErrors.length this.styles.errorClass}} {{this.formGroupClass}}">
    <div class="checkbox">
      <label>
        {{yield}}
        {{this.label}}
      </label>
  
      {{#if this.visibleErrors.length}}
        <span class="{{this.styles.errortextClass}}">
          {{this.visibleErrors.firstObject}}
        </span>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "agPuxs9T",
    "block": "[[[10,0],[15,0,[29,[[30,0,[\"styles\",\"checkboxWrapperClass\"]],\" \",[52,[30,0,[\"isFocused\"]],[30,0,[\"styles\",\"focusClass\"]]],\" \",[52,[30,0,[\"visibleErrors\",\"length\"]],[30,0,[\"styles\",\"errorClass\"]]],\" \",[30,0,[\"formGroupClass\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"checkbox\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"visibleErrors\",\"length\"]],[[[1,\"      \"],[10,1],[15,0,[29,[[30,0,[\"styles\",\"errortextClass\"]]]]],[12],[1,\"\\n        \"],[1,[30,0,[\"visibleErrors\",\"firstObject\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-displays/checkbox.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});