define("ember-form-master-2000/components/fm-widgets/textarea", ["exports", "@ember/legacy-built-in-components", "@ember/object/computed"], function (_exports, _legacyBuiltInComponents, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions, ember/no-attrs-in-components */
  var _default = _legacyBuiltInComponents.TextArea.extend({
    placeholder: (0, _computed.oneWay)('widgetAttrs.placeholder'),
    rows: (0, _computed.oneWay)('widgetAttrs.rows'),
    cols: (0, _computed.oneWay)('widgetAttrs.cols'),
    disabled: (0, _computed.oneWay)('widgetAttrs.disabled'),
    afterRender() {
      this.attrs.registerWidgetId(this.elementId);
    },
    focusOut(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
      if (this.onBlur && typeof this.onBlur === 'function') {
        this.onBlur(e, this);
      }
    },
    keyUp(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
      if (this.onKeyUp && typeof this.onKeyUp === 'function') {
        this.onKeyUp(e, this);
      }
    },
    focusIn(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
      if (this.onFocus && typeof this.onFocus === 'function') {
        this.onFocus(e, this);
      }
    }
  });
  _exports.default = _default;
});