define("ember-form-master-2000/templates/components/fm-widgets/checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
      <Input
    @type='checkbox'
    @checked={{this.value}}
    name={{this.widgetAttrs.name}}
    tabindex={{this.widgetAttrs.tabindex}}
    />
  
  */
  {
    "id": "v0PBj6Qg",
    "block": "[[[1,\"  \"],[8,[39,0],[[16,3,[30,0,[\"widgetAttrs\",\"name\"]]],[16,\"tabindex\",[30,0,[\"widgetAttrs\",\"tabindex\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-widgets/checkbox.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});