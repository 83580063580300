define("ember-form-master-2000/helpers/is-equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isEqualHelper(params) {
    return params[0] === params[1];
  }
  var _default = (0, _helper.helper)(isEqualHelper);
  _exports.default = _default;
});