define("ember-form-master-2000/components/fm-displays/checkbox", ["exports", "ember-form-master-2000/templates/components/fm-displays/checkbox", "ember-form-master-2000/components/fm-displays/base"], function (_exports, _checkbox, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    layout: _checkbox.default,
    inputClasses: null
  });
  _exports.default = _default;
});