define("ember-form-master-2000/initializers/ember-form-master-2000", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* container, application */
  ) {
    // application.inject('route', 'foo', 'service:foo');
  }
  var _default = {
    name: 'ember-form-master-2000',
    initialize: initialize
  };
  _exports.default = _default;
});