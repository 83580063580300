define("ember-keyboard/mixins/ember-keyboard", ["exports", "@ember/service", "@ember/object/evented", "@ember/object/mixin", "ember-keyboard/utils/handle-key-event", "@ember/debug"], function (_exports, _service, _evented, _mixin, _handleKeyEvent, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create(_evented.default, {
    keyboardPriority: 0,
    keyboard: (0, _service.inject)(),
    init(...args) {
      (true && !(false) && (0, _debug.deprecate)('`EKMixin` of ember-keyboard is deprecated. Please use the @keyResponder decorator instead.', false, {
        id: 'ember-keyboard.ember-keyboard-mixin',
        for: 'ember-keyboard',
        since: '6.0.2',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#ember-keyboard-mixin'
      }));
      this.keyboard.register(this);
      return this._super(...args);
    },
    willDestroyElement(...args) {
      this._super(...args);
      this.keyboard.unregister(this);
    },
    willDestroy(...args) {
      this._super(...args);
      this.keyboard.unregister(this);
    },
    // These next two methods adapt this mixin to conform to the new responder API.
    // In the future, once we have good alternatives, we expect all of this addon's
    // mixins to be deprecated and removed, but for now this will let it execute
    // without triggering deprecation warnings.
    canHandleKeyboardEvent(event) {
      for (let listenerName of (0, _handleKeyEvent.getListenerNames)(event)) {
        if (this.has(listenerName)) {
          return true;
        }
      }
      return false;
    },
    handleKeyboardEvent(event, ekEvent) {
      (0, _handleKeyEvent.triggerViaLegacyResponderApi)(this, event, ekEvent);
    }
  });
  _exports.default = _default;
});