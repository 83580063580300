define("ember-form-master-2000/templates/components/fm-widgets/textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.label}}
    <label class='{{this.labelClass}}'>{{this.label}}</label>
  {{/if}}
  
  {{#if this.isSelect}}
  
    {{fm-select
      content=this.content
      optionValuePath=this.optionValuePath
      optionLabelPath=this.optionLabelPath
      prompt=this.prompt
      value=this.value
    }}
  
  {{/if}}
  
  
  {{#if this.isTextarea}}
  
  {{/if}}
  
  {{#if this.isBasicInput}}
  
    {{input
      type=this.type
      value=this.value
      classNameBindings='errorClass inputClass'
      placeholder=this.placeholder}}
  
  {{/if}}
  
  {{#if this.errors}}
    {{fm-errortext errors=this.errors}}
  {{/if}}
  
  {{#if this.helptext}}
    {{fm-helptext helptext=this.helptext}}
  {{/if}}
  
  */
  {
    "id": "Mhsn+MDr",
    "block": "[[[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,\"label\"],[15,0,[29,[[30,0,[\"labelClass\"]]]]],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isSelect\"]],[[[1,\"\\n  \"],[1,[28,[35,1],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"prompt\",\"value\"],[[30,0,[\"content\"]],[30,0,[\"optionValuePath\"]],[30,0,[\"optionLabelPath\"]],[30,0,[\"prompt\"]],[30,0,[\"value\"]]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isTextarea\"]],[[[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isBasicInput\"]],[[[1,\"\\n  \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"placeholder\",\"class\"],[[30,0,[\"type\"]],[30,0,[\"value\"]],[30,0,[\"placeholder\"]],[28,[37,3],[[52,[33,4],[28,[37,5],[\"errorClass\",[33,4]],null]],\" \",[52,[33,6],[28,[37,5],[\"inputClass\",[33,6]],null]],\" \"],null]]]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"errors\"]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"errors\"],[[30,0,[\"errors\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"helptext\"]],[[[1,\"  \"],[1,[28,[35,8],null,[[\"helptext\"],[[30,0,[\"helptext\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"fm-select\",\"input\",\"concat\",\"errorClass\",\"-normalize-class\",\"inputClass\",\"fm-errortext\",\"fm-helptext\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-widgets/textarea.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});