define("ember-form-master-2000/components/fm-displays/base", ["exports", "@ember/object", "@ember/service", "@ember/component"], function (_exports, _object, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    styles: (0, _service.inject)('fm-config'),
    inputClasses: (0, _object.computed)('styles.{errorClass,inputClass}', 'visibleErrors', function () {
      let classNames = [];
      if (this.get('formControlClass')) {
        classNames.push('formControlClass');
      } else {
        classNames.push('styles.inputClass');
      }
      let visibleErrors = this.get('visibleErrors');
      if (visibleErrors && visibleErrors.length > 0) {
        classNames.push('styles.errorClass');
      }
      return classNames.map(classPropertyName => this.get(classPropertyName));
    })
  });
  _exports.default = _default;
});