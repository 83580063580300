define("ember-form-master-2000/templates/components/fm-submit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    data-test={{this.dataTest}}
    value={{this.value}}
    disabled={{this.disabled}}
    class='{{this.submitButtonClass}}'
    type="submit">
    {{#if this.value}}
      {{this.value}}
    {{/if}}
    {{yield}}
  </button>
  
  */
  {
    "id": "WL42Q1Th",
    "block": "[[[10,\"button\"],[15,\"data-test\",[30,0,[\"dataTest\"]]],[15,2,[30,0,[\"value\"]]],[15,\"disabled\",[30,0,[\"disabled\"]]],[15,0,[29,[[30,0,[\"submitButtonClass\"]]]]],[14,4,\"submit\"],[12],[1,\"\\n\"],[41,[30,0,[\"value\"]],[[[1,\"    \"],[1,[30,0,[\"value\"]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-submit.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});