define("ember-form-master-2000/components/fm-widgets/radio-group", ["exports", "@ember/component", "ember-form-master-2000/templates/components/fm-widgets/radio-group"], function (_exports, _component, _radioGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions */
  var _default = _component.default.extend({
    layout: _radioGroup.default,
    actions: {
      radioButtonInteraction() {
        if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
          this.onUserInteraction();
        }
      }
    }
  });
  _exports.default = _default;
});