define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ldwQ+NzZ",
    "block": "[[[46,[33,1],null,[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"onClickOverlay\",\"onClose\"],[[33,2],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[33,17],[33,18],[33,19],[33,20],[33,21],[33,22],[33,23],[33,24],[33,25],[28,[37,26],[[30,0],\"onClickOverlay\"],null],[28,[37,26],[[30,0],\"onClose\"],null]]],[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"component\",\"modalDialogComponentName\",\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"action\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});