window.deprecationWorkflow = window.deprecationWorkflow || {};
window.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchId: "ember-qunit.deprecate-legacy-apis" },
    { handler: "silence", matchId: "ember-component.send-action" },
    { handler: "silence", matchId: "computed-property.override" },
    { handler: "silence", matchId: "ember-data:legacy-test-helper-support" },
    { handler: "silence", matchId: "ember-keyboard.ember-keyboard-mixin" },
    {
      handler: "silence",
      matchId: "ember-keyboard.keyboard-first-responder-on-focus-mixin",
    },
    { handler: "silence", matchId: "ember-keyboard.old-propagation-model" },
    { handler: "silence", matchId: "ember-keyboard.first-responder-inputs" },
  ],
};
