define("ember-form-master-2000/components/fm-field", ["exports", "ember-form-master-2000/templates/components/fm-field", "@ember/object", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _fmField, _object, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-useless-escape, ember/closure-actions, ember/no-attrs-in-components */

  const WIDGET_ATTR_ALIASES = ['placeholder', 'maxlength', 'content', 'optionValuePath', 'name', 'tabindex', 'optionLabelPath', 'prompt', 'rows', 'cols', 'spellcheck', 'disabled', 'targetValue'];
  const WidgetAttrs = _object.default.extend({
    field: null,
    /*
      Aliases properties on the `fm-field` component which should be
      passed into the field's widget as `widgetAttrs`.
       With Ember 2.3 adoption this should be phased out so that users
      pass `widgetAttrs` in directly using the `hash` helper.
       @method init
      @return {void}
    */
    init() {
      this._super();
      WIDGET_ATTR_ALIASES.forEach(field => {
        (0, _object.defineProperty)(this, field, (0, _computed.alias)('field.' + field));
      });
    }
  });

  /**
   * @class FmField
   *
   * `fm-field` controls the functionality of the form field.  It should not
   * do anything relating to the style or appearance of the field.  That is the
   * responsibility of the `display` component.
   **/
  var _default = _component.default.extend({
    layout: _fmField.default,
    tagName: '',
    isFocused: false,
    isValid: false,
    display: (0, _object.computed)('widget', {
      get() {
        const widget = this.get('widget');
        if (widget === 'checkbox') {
          return 'checkbox';
        } else if (widget === 'radio') {
          return 'radio';
        } else if (widget === 'radio-group') {
          return 'radio-group';
        } else {
          return 'default';
        }
      },
      set(key, value) {
        return this._display = value;
      }
    }),
    displayName: (0, _object.computed)(function () {
      return this.get('fmConfig.displayBasePath') + this.get('display');
    }),
    placeholder: null,
    label: null,
    helptext: null,
    value: null,
    fmConfig: (0, _service.inject)('fm-config'),
    dataTest: (0, _object.computed)(function () {
      return this.get('data-test');
    }),
    widgetAttrs: (0, _object.computed)({
      get() {
        // hack to support legacy apis
        return WidgetAttrs.create({
          field: this
        });
      },
      set(key, value) {
        return this._widgetAttrs = value;
      }
    }),
    widget: 'input',
    widgetName: (0, _object.computed)('widget', function () {
      return this.get('fmConfig.widgetBasePath') + this.get('widget');
    }),
    generateSafeId(id) {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = id;
      id = tmp.textContent || tmp.innerText || "";
      id = id.replace(/[\.,\/#!$%\^&\*;:{}=\`'"~()]/g, "");
      id = id.replace(/\s/g, "-");
      return id;
    },
    shouldShowErrors: (0, _object.computed)('fmConfig.showErrorsByDefault', {
      get() {
        return this.get('fmConfig.showErrorsByDefault');
      },
      set(key, value) {
        return this._shouldShowErrors = value;
      }
    }),
    visibleErrors: (0, _object.computed)('shouldShowErrors', 'errors.[]', function () {
      return this.get('shouldShowErrors') ? this.get('errors') : [];
    }),
    actions: {
      onKeyUp(e, instance) {
        if (this.onKeyUp && typeof this.onKeyUp === 'function') {
          this.onKeyUp(e, instance);
        }
      },
      onBlur(e, instance) {
        this.set('isFocused', false);
        if (this.onBlur && typeof this.onBlur === 'function') {
          this.onBlur(e, instance);
        }
      },
      onFocus(e, instance) {
        this.set('isFocused', true);
        if (this.onFocus && typeof this.onFocus === 'function') {
          this.onFocus(e, instance);
        }
      },
      userInteraction() {
        this.set('shouldShowErrors', true);
        if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
          this.onUserInteraction();
        }
      },
      registerWidgetId(id) {
        this.set('forAttribute', id);
      }
    }
  });
  _exports.default = _default;
});