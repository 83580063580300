define("ember-form-master-2000/templates/components/fm-displays/radio", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{this.styles.radioClass}}">
    <label>
      {{yield this.inputClasses}}
      {{this.label}}
    </label>
  </div>
  
  */
  {
    "id": "05j/2KD0",
    "block": "[[[10,0],[15,0,[29,[[30,0,[\"styles\",\"radioClass\"]]]]],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[18,1,[[30,0,[\"inputClasses\"]]]],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-displays/radio.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});