define("ember-form-master-2000/services/fm-config", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    widgetBasePath: 'fm-widgets/',
    displayBasePath: 'fm-displays/',
    submitButtonClass: 'btn btn-primary',
    errorClass: 'has-error',
    wrapperClass: 'form-group',
    labelClass: 'control-label',
    inputClass: 'form-control',
    textareaClass: 'form-control',
    selectClass: 'form-control',
    formClass: 'form-vertical',
    checkboxWrapperClass: 'form-group',
    focusClass: 'is-focused',
    radioClass: 'radio',
    helptextClass: 'help-block',
    errortextClass: 'help-block',
    isValidClass: 'is-valid',
    /*
    *   If true, always show validation errors. If false, wait until some sort of
    *   user interaction on each field before showing errors.
    */
    showErrorsByDefault: true
  });
  _exports.default = _default;
});