define("ember-concurrency-decorators/last-value", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "@ember/object"], function (_exports, _decorator, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This decorator allows you to alias a property to the result of a task. You can also provide a default value to use before the task has completed.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { lastValue } from 'ember-concurrency-decorators';
   *
   * export default class ExampleComponent extends Component {
   *   @task
   *   someTask = function*() {
   *     // ...
   *   };
   *
   *   @lastValue('someTask')
   *   someTaskValue;
   *
   *   @lastValue('someTask')
   *   someTaskValueWithDefault = 'A default value';
   * }
   * ```
   *
   * @function
   * @param {string} taskName the name of the task to read a value from
   */
  // eslint-disable-next-line func-names
  var _default = (0, _decorator.decoratorWithRequiredParams)(function lastValue(target, key, desc, [taskName]) {
    (true && !(typeof taskName === 'string') && (0, _debug.assert)(`ember-concurrency-decorators: @lastValue expects a task name as the first parameter.`, typeof taskName === 'string'));
    const {
      initializer
    } = desc;
    delete desc.initializer;
    const cp = (0, _object.computed)(`${taskName}.lastSuccessful`, function () {
      const lastInstance = (0, _object.get)(this, `${taskName}.lastSuccessful`);
      if (lastInstance) {
        return (0, _object.get)(lastInstance, 'value');
      }
      if (initializer) {
        return initializer.call(this);
      }
      return undefined;
    });

    // @ts-ignore
    return cp(target, key, desc);
  });
  _exports.default = _default;
});