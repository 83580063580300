define("@sentry/ember/index", ["exports", "@sentry/browser", "@ember/runloop", "@ember/debug", "ember", "@sentry/utils", "@embroider/macros/runtime"], function (_exports, _browser, _runloop, _debug, _ember, _utils, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    InitSentryForEmber: true,
    getActiveTransaction: true,
    instrumentRoutePerformance: true,
    init: true
  };
  _exports.InitSentryForEmber = InitSentryForEmber;
  _exports.instrumentRoutePerformance = _exports.init = _exports.getActiveTransaction = void 0;
  var Sentry = _browser;
  Object.keys(_browser).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _browser[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _browser[key];
      }
    });
  });
  function InitSentryForEmber(_runtimeConfig) {
    const config = (0, _runtime.config)("/__w/frontend/frontend/login/node_modules/@sentry/ember").sentryConfig;
    (true && !(config) && (0, _debug.assert)('Missing configuration.', config));
    (true && !(config.sentry || _runtimeConfig) && (0, _debug.assert)('Missing configuration for Sentry.', config.sentry || _runtimeConfig));
    if (!config.sentry) {
      // If environment config is not specified but the above assertion passes, use runtime config.
      config.sentry = {
        ..._runtimeConfig
      };
    }

    // Permanently merge options into config, preferring runtime config
    Object.assign(config.sentry, _runtimeConfig || {});
    const initConfig = Object.assign({}, config.sentry);
    initConfig._metadata = initConfig._metadata || {};
    initConfig._metadata.sdk = {
      name: 'sentry.javascript.ember',
      packages: [{
        name: 'npm:@sentry/ember',
        version: _browser.SDK_VERSION
      }],
      version: _browser.SDK_VERSION
    };
    Sentry.init(initConfig);
    if ((0, _runtime.macroCondition)(true)) {
      if (config.ignoreEmberOnErrorWarning) {
        return;
      }
      (0, _runloop.next)(null, function () {
        (true && (0, _debug.warn)('Ember.onerror found. Using Ember.onerror can hide some errors (such as flushed runloop errors) from Sentry. Use Sentry.captureException to capture errors within Ember.onError or remove it to have errors caught by Sentry directly. This error can be silenced via addon configuration.', !_ember.default.onerror, {
          id: '@sentry/ember.ember-onerror-detected'
        }));
      });
    }
  }
  const getActiveTransaction = () => {
    var _Sentry$getCurrentHub, _Sentry$getCurrentHub2;
    return (_Sentry$getCurrentHub = Sentry.getCurrentHub()) === null || _Sentry$getCurrentHub === void 0 ? void 0 : (_Sentry$getCurrentHub2 = _Sentry$getCurrentHub.getScope()) === null || _Sentry$getCurrentHub2 === void 0 ? void 0 : _Sentry$getCurrentHub2.getTransaction();
  };
  _exports.getActiveTransaction = getActiveTransaction;
  const instrumentRoutePerformance = BaseRoute => {
    const instrumentFunction = async (op, description, fn, args) => {
      const startTimestamp = (0, _utils.timestampWithMs)();
      const result = await fn(...args);
      const currentTransaction = getActiveTransaction();
      if (!currentTransaction) {
        return result;
      }
      currentTransaction.startChild({
        op,
        description,
        startTimestamp
      }).finish();
      return result;
    };
    return {
      [BaseRoute.name]: class extends BaseRoute {
        beforeModel(...args) {
          return instrumentFunction('ember.route.beforeModel', this.fullRouteName, super.beforeModel.bind(this), args);
        }
        async model(...args) {
          return instrumentFunction('ember.route.model', this.fullRouteName, super.model.bind(this), args);
        }
        async afterModel(...args) {
          return instrumentFunction('ember.route.afterModel', this.fullRouteName, super.afterModel.bind(this), args);
        }
        async setupController(...args) {
          return instrumentFunction('ember.route.setupController', this.fullRouteName, super.setupController.bind(this), args);
        }
      }
    }[BaseRoute.name];
  };
  _exports.instrumentRoutePerformance = instrumentRoutePerformance;
  // init is now the preferred way to call initialization for this addon.
  const init = InitSentryForEmber;
  _exports.init = init;
});