define("ember-concurrency-decorators/index", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "ember-concurrency", "ember-concurrency-decorators/last-value"], function (_exports, _decorator, _debug, _emberConcurrency, _lastValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  Object.defineProperty(_exports, "lastValue", {
    enumerable: true,
    get: function () {
      return _lastValue.default;
    }
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = void 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /**
   * This utility function assures compatibility with the Ember object model style
   * and initializer syntax required by Babel 6.
   *
   * For native classes using the method shorthand style (TypeScript & Babel 7),
   * this function will access the `value`. For legacy code it will get the value
   * from the initializer.
   *
   * // Ember object model
   * export default EmberObject.extend({
   *   @task
   *   someTask: function*() {}
   * });
   *
   * // Class syntax with initializers
   * export default class extends EmberObject {
   *   @task
   *   someTask = function*() {}
   * }
   *
   * @param desc
   * @returns {object|null}
   * @private
   */
  function extractValue(desc) {
    if (typeof desc.initializer === 'function') {
      return desc.initializer.call(undefined);
    }
    if (typeof desc.get === 'function') {
      return desc.get.call(undefined);
    }
    if (desc.value) {
      return desc.value;
    }
    return undefined;
  }
  function isTaskFunction(value) {
    return typeof value === 'function';
  }
  function isEncapsulatedTask(value) {
    return typeof value === 'object' && value !== null && isTaskFunction(value.perform);
  }

  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskProperty`.
   *
   * @param desc
   * @returns {TaskProperty}
   * @private
   */
  function createTaskFromDescriptor(desc) {
    const value = extractValue(desc);
    if (isTaskFunction(value)) {
      return (0, _emberConcurrency.task)(value);
    }
    if (isEncapsulatedTask(value)) {
      return (0, _emberConcurrency.task)(value);
    }
    (true && !(false) && (0, _debug.assert)('ember-concurrency-decorators: Can only decorate a generator function as a task or an object with a generator method `perform` as an encapsulated task.'));
  }

  /**
   * Takes a `PropertyDescriptor` and turns it into an ember-concurrency
   * `TaskGroupProperty`.
   *
   * @param desc
   * @returns {TaskGroupProperty}
   * @private
   */
  function createTaskGroupFromDescriptor(_desc) {
    return (0, _emberConcurrency.taskGroup)();
  }

  /**
   * Applies the `options` provided using the chaining API on the given `task`.
   *
   * @param options
   * @param {TaskProperty|TaskGroupProperty} task
   * @private
   */

  function applyOptions(options, task) {
    const keys = Object.keys(options);
    for (const key of keys) {
      const value = options[key];
      (true && !(value !== undefined) && (0, _debug.assert)(`ember-concurrency-decorators: Option '${key}' is not a valid value`, value !== undefined));
      (true && !(typeof task[key] === 'function') && (0, _debug.assert)(`ember-concurrency-decorators: Option '${key}' is not a valid function`, typeof task[key] === 'function'));
      if (value === true) {
        task[key]();
      } else {
        task[key](value);
      }
    }

    // The CP decorator gets executed in `createDecorator`
    return task;
  }

  /**
   * Creates a decorator function that transforms the decorated property using the
   * given `propertyCreator` and accepts an optional user provided options hash,
   * that that will be merged with the `baseOptions`.
   *
   * @param {function} propertyCreator
   * @param {object} [baseOptions={}]
   * @private
   */

  function createDecorator(propertyCreator, baseOptions = {}) {
    return (0, _decorator.decoratorWithParams)((target, key, desc, [userOptions] = []) => {
      const {
        initializer,
        value
      } = desc;
      delete desc.initializer;
      delete desc.value;
      return applyOptions({
        ...baseOptions,
        ...userOptions
      }, propertyCreator({
        ...desc,
        initializer,
        value
      }))(target, key, desc);
    });
  }

  /**
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @task
   *   *plainTask() {}
   *
   *   @task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  const task = createDecorator(createTaskFromDescriptor);

  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  _exports.task = task;
  const restartableTask = createDecorator(createTaskFromDescriptor, {
    restartable: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  _exports.restartableTask = restartableTask;
  const dropTask = createDecorator(createTaskFromDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  _exports.dropTask = dropTask;
  const keepLatestTask = createDecorator(createTaskFromDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskProperty}
   */
  _exports.keepLatestTask = keepLatestTask;
  const enqueueTask = createDecorator(createTaskFromDescriptor, {
    enqueue: true
  });

  /**
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import EmberObject from '@ember/object';
   * import { task taskGroup } from 'ember-concurrency-decorators';
   *
   * class extends EmberObject {
   *   @taskGroup({ maxConcurrency: 5 }) someTaskGroup;
   *
   *   @task({ group: 'someTaskGroup' })
   *   *someTask() {}
   *
   *   @task({ group: 'someTaskGroup' })
   *   *anotherTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  _exports.enqueueTask = enqueueTask;
  const taskGroup = createDecorator(createTaskGroupFromDescriptor);

  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  _exports.taskGroup = taskGroup;
  const restartableTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    restartable: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  _exports.restartableTaskGroup = restartableTaskGroup;
  const dropTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  _exports.dropTaskGroup = dropTaskGroup;
  const keepLatestTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}]
   * @return {TaskGroupProperty}
   */
  _exports.keepLatestTaskGroup = keepLatestTaskGroup;
  const enqueueTaskGroup = createDecorator(createTaskGroupFromDescriptor, {
    enqueue: true
  });
  _exports.enqueueTaskGroup = enqueueTaskGroup;
});