define("ember-form-master-2000/components/fm-widgets/checkbox", ["exports", "ember-form-master-2000/templates/components/fm-widgets/checkbox", "@ember/component"], function (_exports, _checkbox, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions */
  var _default = _component.default.extend({
    layout: _checkbox.default,
    change(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
    },
    focusOut(e) {
      if (this.onBlur && typeof this.onBlur === 'function') {
        this.onBlur(e, this);
      }
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
    },
    focusIn(e) {
      if (this.onFocus && typeof this.onFocus === 'function') {
        this.onFocus(e, this);
      }
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction();
      }
    }
  });
  _exports.default = _default;
});