define("ember-form-master-2000/templates/components/fm-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#component this.displayName
    formControlClass=this.formControlClass
    label=this.label
    helptext=this.helptext
    forAttribute=this.forAttribute
    visibleErrors=this.visibleErrors
    isFocused=this.isFocused
    isValid=this.isValid
    formGroupClass=this.class
    dataTest=this.dataTest
    as |inputClasses|
  }}
  
    {{component this.widgetName
      value=this.value
      action=this.action
      onUserInteraction=(action 'userInteraction')
      onKeyUp=(action 'onKeyUp')
      onFocus=(action 'onFocus')
      onBlur=(action 'onBlur')
      classNames=inputClasses
      registerWidgetId=(action 'registerWidgetId')
      widgetAttrs=this.widgetAttrs
    }}
  
    {{yield}}
  
  {{/component}}
  
  */
  {
    "id": "pD05Oil1",
    "block": "[[[46,[30,0,[\"displayName\"]],null,[[\"formControlClass\",\"label\",\"helptext\",\"forAttribute\",\"visibleErrors\",\"isFocused\",\"isValid\",\"formGroupClass\",\"dataTest\"],[[30,0,[\"formControlClass\"]],[30,0,[\"label\"]],[30,0,[\"helptext\"]],[30,0,[\"forAttribute\"]],[30,0,[\"visibleErrors\"]],[30,0,[\"isFocused\"]],[30,0,[\"isValid\"]],[30,0,[\"class\"]],[30,0,[\"dataTest\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[46,[30,0,[\"widgetName\"]],null,[[\"value\",\"action\",\"onUserInteraction\",\"onKeyUp\",\"onFocus\",\"onBlur\",\"classNames\",\"registerWidgetId\",\"widgetAttrs\"],[[30,0,[\"value\"]],[30,0,[\"action\"]],[28,[37,1],[[30,0],\"userInteraction\"],null],[28,[37,1],[[30,0],\"onKeyUp\"],null],[28,[37,1],[[30,0],\"onFocus\"],null],[28,[37,1],[[30,0],\"onBlur\"],null],[30,1],[28,[37,1],[[30,0],\"registerWidgetId\"],null],[30,0,[\"widgetAttrs\"]]]],null],[1,\"\\n\\n  \"],[18,2,null],[1,\"\\n\\n\"]],[1]]]]]],[\"inputClasses\",\"&default\"],false,[\"component\",\"action\",\"yield\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-field.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});