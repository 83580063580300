define("ember-form-master-2000/templates/components/fm-displays/default", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div data-test={{this.dataTest}}
       class="{{this.styles.wrapperClass}}
              {{if this.isFocused this.styles.focusClass}}
              {{if this.visibleErrors.length this.styles.errorClass}}
              {{if this.isValid this.styles.isValidClass}}
              {{this.formGroupClass}}">
    {{#if this.label}}
      <label for="{{this.forAttribute}}"
             class="{{this.styles.labelClass}}">
        {{{this.label}}}
      </label>
    {{/if}}
  
    {{yield this.inputClasses}}
  
    {{#if this.visibleErrors.length}}
      <span class="{{this.styles.errortextClass}}">
        {{this.visibleErrors.firstObject}}
      </span>
    {{/if}}
  
    {{#if this.helptext}}
      <span class="{{this.styles.helptextClass}}">
        {{this.helptext}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "h/QflQMH",
    "block": "[[[10,0],[15,\"data-test\",[30,0,[\"dataTest\"]]],[15,0,[29,[[30,0,[\"styles\",\"wrapperClass\"]],\"\\n            \",[52,[30,0,[\"isFocused\"]],[30,0,[\"styles\",\"focusClass\"]]],\"\\n            \",[52,[30,0,[\"visibleErrors\",\"length\"]],[30,0,[\"styles\",\"errorClass\"]]],\"\\n            \",[52,[30,0,[\"isValid\"]],[30,0,[\"styles\",\"isValidClass\"]]],\"\\n            \",[30,0,[\"formGroupClass\"]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,\"label\"],[15,\"for\",[29,[[30,0,[\"forAttribute\"]]]]],[15,0,[29,[[30,0,[\"styles\",\"labelClass\"]]]]],[12],[1,\"\\n      \"],[2,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,1,[[30,0,[\"inputClasses\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"visibleErrors\",\"length\"]],[[[1,\"    \"],[10,1],[15,0,[29,[[30,0,[\"styles\",\"errortextClass\"]]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"visibleErrors\",\"firstObject\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"helptext\"]],[[[1,\"    \"],[10,1],[15,0,[29,[[30,0,[\"styles\",\"helptextClass\"]]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"helptext\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-displays/default.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});