define("ember-form-master-2000/templates/components/fm-widgets/radio", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <input type='radio'
    name={{this.widgetAttrs.name}}
    value={{this.widgetAttrs.targetValue}}
    checked={{this.checked}}>
  
  */
  {
    "id": "mlOv330E",
    "block": "[[[10,\"input\"],[15,3,[30,0,[\"widgetAttrs\",\"name\"]]],[15,2,[30,0,[\"widgetAttrs\",\"targetValue\"]]],[15,\"checked\",[30,0,[\"checked\"]]],[14,4,\"radio\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-widgets/radio.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});