define("ember-form-master-2000/templates/components/fm-widgets/select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.widgetAttrs.prompt}}
    <option disabled selected={{is-not this.value}}>
      {{this.widgetAttrs.prompt}}
    </option>
  {{/if}}
  
  {{#each this.optionContent as |item|}}
    <option value="{{if this.widgetAttrs.optionValuePath (get item this.widgetAttrs.optionValuePath) item}}"
            selected={{is-equal (if this.widgetAttrs.optionValuePath (get item this.widgetAttrs.optionValuePath) item) this.value}}>
      {{get item this.widgetAttrs.optionLabelPath}}
    </option>
  {{/each}}
  
  */
  {
    "id": "typ5WLqo",
    "block": "[[[41,[30,0,[\"widgetAttrs\",\"prompt\"]],[[[1,\"  \"],[10,\"option\"],[14,\"disabled\",\"\"],[15,\"selected\",[28,[37,1],[[30,0,[\"value\"]]],null]],[12],[1,\"\\n    \"],[1,[30,0,[\"widgetAttrs\",\"prompt\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"optionContent\"]]],null]],null],null,[[[1,\"  \"],[10,\"option\"],[15,2,[29,[[52,[30,0,[\"widgetAttrs\",\"optionValuePath\"]],[28,[37,4],[[30,1],[30,0,[\"widgetAttrs\",\"optionValuePath\"]]],null],[30,1]]]]],[15,\"selected\",[28,[37,5],[[52,[30,0,[\"widgetAttrs\",\"optionValuePath\"]],[28,[37,4],[[30,1],[30,0,[\"widgetAttrs\",\"optionValuePath\"]]],null],[30,1]],[30,0,[\"value\"]]],null]],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,1],[30,0,[\"widgetAttrs\",\"optionLabelPath\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"item\"],false,[\"if\",\"is-not\",\"each\",\"-track-array\",\"get\",\"is-equal\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-widgets/select.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});