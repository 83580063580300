define("@ember/legacy-built-in-components/index", ["exports", "ember", "@embroider/macros/runtime"], function (_exports, _ember, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TextField = _exports.TextArea = _exports.LinkComponent = _exports.Checkbox = void 0;
  /* eslint-disable ember/new-module-imports */

  const Checkbox = (0, _runtime.macroCondition)(true) ? _ember.default._LegacyCheckbox.extend() : _ember.default.Checkbox;
  _exports.Checkbox = Checkbox;
  const TextField = (0, _runtime.macroCondition)(true) ? _ember.default._LegacyTextField.extend() : _ember.default.TextField;
  _exports.TextField = TextField;
  const TextArea = (0, _runtime.macroCondition)(true) ? _ember.default._LegacyTextArea.extend() : _ember.default.TextArea;
  _exports.TextArea = TextArea;
  const LinkComponent = (0, _runtime.macroCondition)(true) ? _ember.default._LegacyLinkComponent.extend() : _ember.default.LinkComponent;
  _exports.LinkComponent = LinkComponent;
});