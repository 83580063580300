define("ember-form-master-2000/templates/components/fm-widgets/radio-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.widgetAttrs.content as |option|}}
    {{fm-field
      widget='radio'
      display='radio'
      value=this.value
      targetValue=(get option this.widgetAttrs.optionValuePath)
      label=(get option this.widgetAttrs.optionLabelPath)
      onUserInteraction=(action 'radioButtonInteraction')
    }}
  {{/each}}
  
  */
  {
    "id": "SdlfKb9K",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"widgetAttrs\",\"content\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"widget\",\"display\",\"value\",\"targetValue\",\"label\",\"onUserInteraction\"],[\"radio\",\"radio\",[30,0,[\"value\"]],[28,[37,3],[[30,1],[30,0,[\"widgetAttrs\",\"optionValuePath\"]]],null],[28,[37,3],[[30,1],[30,0,[\"widgetAttrs\",\"optionLabelPath\"]]],null],[28,[37,4],[[30,0],\"radioButtonInteraction\"],null]]]]],[1,\"\\n\"]],[1]],null]],[\"option\"],false,[\"each\",\"-track-array\",\"fm-field\",\"get\",\"action\"]]",
    "moduleName": "ember-form-master-2000/templates/components/fm-widgets/radio-group.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});