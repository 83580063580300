define("ember-form-master-2000/components/fm-widgets/radio", ["exports", "ember-form-master-2000/templates/components/fm-widgets/radio", "@ember/component", "@ember/object"], function (_exports, _radio, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions */
  var _default = _component.default.extend({
    layout: _radio.default,
    checked: (0, _object.computed)('value', 'widgetAttrs.targetValue', function () {
      return !!this.get('value') && this.get('value') === this.get('widgetAttrs.targetValue');
    }),
    didReceiveAttrs() {
      // override default behaviour form BaseWidgetMixin
    },
    change() {
      this.set('value', this.get('widgetAttrs.targetValue'));
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction();
      }
    },
    focusOut(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction();
      }
      if (this.onBlur && typeof this.onBlur === 'function') {
        this.onBlur(e, this);
      }
    }
  });
  _exports.default = _default;
});