define("ember-form-master-2000/components/fm-submit", ["exports", "ember-form-master-2000/templates/components/fm-submit", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed"], function (_exports, _fmSubmit, _component, _object, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _fmSubmit.default,
    classNameBindings: ['wrapperClass'],
    fmConfig: (0, _service.inject)('fm-config'),
    init() {
      this._super(this);
    },
    submitButtonClass: (0, _computed.reads)('fmConfig.submitButtonClass'),
    wrapperClass: (0, _computed.reads)('fmConfig.wrapperClass'),
    tagName: 'div',
    dataTest: (0, _object.computed)(function () {
      return this.get('data-test');
    })
  });
  _exports.default = _default;
});