define("ember-form-master-2000/components/fm-form", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions */
  var _default = _component.default.extend({
    init() {
      this._super();
    },
    classNameBindings: ['formClass'],
    formClass: (0, _computed.reads)('fmConfig.formClass'),
    fmConfig: (0, _service.inject)('fm-config'),
    tagName: 'form',
    'for': null,
    submit(e) {
      e.preventDefault();
      this.get('childViews').forEach(childView => {
        if (childView.get('shouldShowErrors') === false) {
          childView.set('shouldShowErrors', true);
        }
      });
      if (this.action && typeof this.action === 'function') {
        this.action(e, this.for);
      }
    }
  });
  _exports.default = _default;
});