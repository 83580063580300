define("ember-concurrency/-helpers", ["exports", "@ember/object", "@ember/debug", "@ember/runloop"], function (_exports, _object, _debug, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskHelperClosure = taskHelperClosure;
  function taskHelperClosure(helperName, taskMethod, _args, hash) {
    let task = _args[0];
    let outerArgs = _args.slice(1);
    return (0, _runloop.bind)(null, function (...innerArgs) {
      if (!task || typeof task[taskMethod] !== 'function') {
        (true && !(false) && (0, _debug.assert)(`The first argument passed to the \`${helperName}\` helper should be a Task object (without quotes); you passed ${task}`, false));
        return;
      }
      if (hash && hash.value) {
        let event = innerArgs.pop();
        innerArgs.push((0, _object.get)(event, hash.value));
      }
      return task[taskMethod](...outerArgs, ...innerArgs);
    });
  }
});