define("ember-form-master-2000/helpers/is-not", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isNotHelper(params) {
    return !params[0];
  }
  var _default = (0, _helper.helper)(isNotHelper);
  _exports.default = _default;
});