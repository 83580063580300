define("ember-form-master-2000/components/fm-widgets/select", ["exports", "ember-form-master-2000/templates/components/fm-widgets/select", "@ember/component", "@ember/object", "@ember/service", "@ember/array", "@ember/object/computed"], function (_exports, _select, _component, _object, _service, _array, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions, ember/no-attrs-in-components */
  var _default = _component.default.extend({
    layout: _select.default,
    fmConfig: (0, _service.inject)('fm-config'),
    tagName: 'select',
    classNameBindings: ['selectClass'],
    attributeBindings: ['isDisabled:disabled'],
    selectClass: (0, _computed.reads)('fmConfig.selectClass'),
    isDisabled: (0, _computed.oneWay)('widgetAttrs.disabled'),
    optionContent: null,
    didReceiveAttrs() {
      this._super(...arguments);
      if (!(0, _array.isArray)(this.widgetAttrs.content)) {
        this.set('optionContent', []);
      } else {
        this.set('optionContent', this.widgetAttrs.content);
      }
    },
    change() {
      const selectEl = document.getElementById(this.elementId);
      const wAttrs = this.getAttr('widgetAttrs');
      const selectedIndex = selectEl.selectedIndex;
      const content = (0, _object.get)(wAttrs, 'content');

      // decrement index by 1 if we have a prompt
      const hasPrompt = !!(0, _object.get)(wAttrs, 'prompt');
      const contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
      const selection = content.objectAt(contentIndex);
      let path = (0, _object.get)(wAttrs, 'optionValuePath');
      if (path === undefined) {
        path = '';
      }
      const value = path.length > 0 ? (0, _object.get)(selection, path) : selection;
      // support using two way binding or data down/actions up
      if (typeof this.attrs.action === 'function') {
        this.attrs.action(value);
      } else {
        this.attrs.value.update(value);
      }
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction();
      }
    },
    focusOut(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
      if (this.onBlur && typeof this.onBlur === 'function') {
        this.onBlur(e, this);
      }
    },
    focusIn(e) {
      if (this.onUserInteraction && typeof this.onUserInteraction === 'function') {
        this.onUserInteraction(e, this);
      }
      if (this.onFocus && typeof this.onFocus === 'function') {
        this.onFocus(e, this);
      }
    }
  });
  _exports.default = _default;
});